// Bootstrap colors / variable overrides

// @import "~@aws-amplify/ui-angular/theme.css";
// @import "/node_modules/@fontsource/lato/100.css";
// @import "node_modules/@fontsource/lato/300.css";
// @import "node_modules/@fontsource/lato/400.css";
// @import "node_modules/@fontsource/lato/700.css";
// @import "node_modules/@fontsource/roboto/100.css";
// @import "node_modules/@fontsource/roboto/300.css";
// @import "node_modules/@fontsource/roboto/400.css";
// @import "node_modules/@fontsource/roboto/500.css";
// @import "node_modules/@fontsource/roboto/700.css";

@import "custom.scss";
@import "node_modules/bootstrap/scss/bootstrap";

// @import "~@ng-select/ng-select/themes/default.theme.css";

@import "./assets/theming/theme";
@import "./assets/utilities/modals";
// @import "~primeng/resources/primeng.min.css";
// @import "primeicons/primeicons.css";
@import "node_modules/ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
@import "node_modules/ag-grid-community/styles/ag-theme-quartz.css";

@font-face {
  font-family: agGridQuartz2;
  src: url(./assets/fonts/agGridQuartz.woff2);
}

:root {
  --faded12: rgb(209 230 243 / 12%);
  --faded32: rgb(209 230 243 / 32%);
  --faded50: rgb(209 230 243 / 50%);
  --white50: rgb(255 255 255 / 50%);
  --white60: rgb(255 255 255 / 6%);
  --primary: #d11f7a;
  --dark: #1c1a32;
  --secondary: #2b2940;
  --darkerGrey: #3e4055;
  --background: #2b2940;
  --autoFill: #2b2940b4;
  --trackThumb: #606579;
  --text-large: 0.875rem;
  --text-medium: 0.75rem;
  --text-label: 0.6875rem;
  --text-small: 0.625rem;
  --sm: 500px;
  --md: 1000px;
  --logo-dev-color: #44803c;
  --logo-test-color: #726acc;
  --logo-default-color: #d11f7a;
  --warning: #f1c40f;
  --status-yellow: #e4ad5e;
  --status-green: #4cb25b;
  --status-red: #f1c40f;
  --status-grey: rgb(209 230 243 / 32%);
}
.ag-theme-quartz-dark {
  --ag-foreground-color: #fff;
  --ag-background-color: var(--dark);
  --ag-header-foreground-color: #fff;
  --ag-header-background-color: var(--dark);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgba(255, 255, 255, 0.507);
  --ag-border-color: transparent;
  --ag-row-hover-color: rgba(43, 41, 64, 0.4);
  --ag-control-panel-background-color: #2b2940;
  --ag-side-button-selected-background-color: var(--secondary);
  --ag-font-size: 12px;
  --ag-icon-font-color: var(--primary);
  --ag-checkbox-checked-color: var(--primary);
  --ag-selected-row-background-color: #2b2940;
  --ag-icon-font-family: agGridQuartz2;
  --ag-wrapper-border-radius: 6px;
}

.panelTable .ag-theme-quartz-dark {
  --ag-header-background-color: var(--secondary);
  --ag-background-color: var(--secondary);
}

.panelTable .ag-row-selected::before,
.panelTable .ag-row-hover.ag-row-selected::before {
  background-color: #1c1a329e !important;
  background-image: linear-gradient(#1c1a329e, #1c1a329e);
}

.ag-header-row {
  text-transform: uppercase;
}
.ag-header-cell-sortable .ag-header-cell-label {
  color: rgba(209, 230, 243, 0.4);
}
.ag-checkbox-input-wrapper.ag-checked {
  background-color: #fff;
}
body,
html {
  font-size: 16px;
  background-color: $secondary;
  overflow: hidden;
  position: relative;
  font-family: Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.footer {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}

.app-version {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: smaller;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}

.environment {
  font-size: x-large;
  color: rgba(255, 255, 255, 0.1);
}

.center {
  display: flex;
  justify-content: center;
}

.text-small {
  // 10px
  font-size: var(--text-small);
}

.text-medium {
  // 12px
  font-size: var(--text-medium);
}

.text-large {
  // 14px
  font-size: var(--text-large);
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-0 {
  margin: 0;
}

div#main-content {
  padding: 0;
}

/* Additional color helpers */

.faded12 {
  color: var(--faded12);
}
.bg-faded12 {
  background-color: var(--faded12);
}
.faded32 {
  color: var(--faded32);
}
.bg-faded32 {
  background-color: var(--faded32);
}
.faded50 {
  color: var(--faded50);
}
.bg-faded50 {
  background-color: var(--faded50);
}
.white50 {
  color: var(--white50);
}
.bg-white50 {
  background-color: var(--white50);
}
.bg-darkerGrey {
  background-color: var(--darkerGrey);
}

/* Base styles*/
.headerContainer h1 {
  margin: 0;
}

nav.navbar.navbar-dark.bg-dark {
  height: 60px;
}

.form-group.icon input.ng-invalid.ng-touched:not(:focus),
.form-group.icon textarea.ng-invalid.ng-touched:not(:focus),
.form-group.icon
  .ng-select.ng-invalid.ng-touched:not(.ng-select-opened)
  .ng-select-container {
  background-image: url(/assets/cross.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

app-dc-date-input.ng-invalid .input-group .input-group-prepend {
  border: 0 !important;
}

.form-group.icon input.ng-valid.ng-touched:not(:focus),
.form-group.icon textarea.ng-valid.ng-touched:not(:focus),
.form-group.icon
  .ng-select.ng-valid.ng-touched:not(.ng-select-opened)
  .ng-select-container {
  background-image: url(/assets/tick.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
}

.form-group textarea.ng-touched:not(:focus) {
  background-position-y: 13px !important;
}

.form-group .ng-select .ng-select-container {
  background-position-x: calc(100% - 36px) !important;
}

.login-screen .form-group input {
  background-image: none !important;
}

// REVIEW COMMENTS BUTTONS AND CUSTOM BUTTON CLASSES!!!!!
button,
input[type="submit"],
input[type="reset"],
input {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

/* Custom form controls */

.form-check.custom-control {
  display: inline-flex;
}

.form-check {
  margin-bottom: 0.5rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $primary;
  background-color: $primary;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: transparent !important;
  box-shadow: 0px 0px 0 1px $white;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #f1f3f3;
  background-color: $secondary;
  border-color: #8f9ea8;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: $dark;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: $white;
  opacity: 0.32;
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: $primary;
}
.form-control:disabled {
  background-color: $secondary;
  opacity: 0.375;
}

.fieldLabel:disabled {
  opacity: 0.375;
}

.formArray.disabled
  .dynamicDisable
  .custom-control-input
  ~ .custom-control-label::before {
  color: $primary !important;
  border-color: $primary !important;
  background-color: $primary !important;
  color: $white !important;
}

.formArray.disabled
  .dynamicDisable
  > .custom-checkbox
  .custom-control-input
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

/* Bootstrap popover */

button.btn.btn-link.popOver {
  background-image: url(/assets/info-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  top: 3px;
  height: 16px;
  background-position-x: center;
  position: absolute;
  right: 0;
  padding: 0;
  border-radius: 50%;

  &.warning {
    background-image: url(/assets/statusIncomplete.svg);
  }
}

button.btn.btn-link.popOver:focus {
  box-shadow: 0 0 0 1px $white;
  border-radius: 50%;
}

.ddqPopOver.popover .popover-body ul {
  list-style-type: disc;
  margin-left: 0.75rem;
}

.ddqPopOver.popover .popover-body p {
  font-size: $font-size-sm;
}

app-dc-popover.helpText button.btn.btn-link.popOver {
  position: relative;
  left: 6px;
  top: -1px;
}

/* Date picker */

button.btn.btn-link.calendar {
  border-radius: 0.25rem 0 0 0.25rem;
  background-color: $secondary;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  padding-top: 7px;
  border-right-color: transparent;
  border-right: 0;
}

.dark button.btn.btn-link.calendar {
  background-color: $dark;
}

.readonly {
  opacity: 0.6;
  pointer-events: none;

  .form-control {
    text-overflow: ellipsis;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $secondary;
  opacity: 1;
}

.dark .form-control:disabled,
.dark .form-control[readonly] {
  background-color: $dark;
}

.btn.btn-link.calendar:focus,
.btn.btn-link.calendar .focus {
  outline: 0;
}

.dark .form-control:disabled,
.dark .form-control[readonly] {
  background-color: $dark;
}

.ngb-dp-header {
  background-color: $secondary !important;
}

.ngb-dp-header .btn-link {
  font-weight: 400;
  color: $white;
}

.ngb-dp-header select.custom-select {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
  min-width: 70px;
}

.ngb-dp-header select.custom-select:focus {
  box-shadow: none;
  border-color: $white;
}

.ngb-dp-navigation-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 4rem;
  flex: 1 1 4rem;
}

.btn-light.bg-primary.text-white {
  background-color: $primary !important;
}

.ngb-dp-month {
  background-color: $secondary;
}

.ngb-dp-week.ngb-dp-weekdays {
  background-color: $secondary;
  color: $white;
}

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday {
  font-size: 0.75rem;
}

.ngb-dp-weekday.small {
  color: $white;
  font-style: normal;
  text-transform: uppercase;
}

.btn-light.text-muted {
  color: #d1e6f3 !important;
  opacity: 0.32;
}

.btn-light {
  color: $white;
}

.ngb-dp-day.ngb-dp-today .btn-light {
  color: #e06764;
}

.ngb-dp-day .btn-light:hover {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

input#datePickerComponent::placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

ngb-datepicker.dropdown-menu.show {
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 4px rgb(28 26 50 / 32%);
}

/* Date-picker Range */

.dark.input-group .form-control {
  background-color: $dark;
}

.input-group .ngb-dp-month-name {
  background-color: $secondary;
}

/* button form array */

.btn.minus,
.btn.plus {
  color: #d11f7a;
  font-size: 20px;
  display: block;
  padding: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  position: relative;
  top: -1.5rem;
  left: 5px;
}

.plus:hover,
.minus:hover {
  filter: brightness(150%);
}

.plus {
  background-image: url(/assets/plus-circle.svg);
}

.minus {
  background-image: url(/assets/minus-circle.svg);
}

/* NG-select */
.ng-dropdown-panel {
  background-color: $secondary;
  border: 1px solid $secondary !important;
  box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  margin-top: 9px !important;
  border-radius: 4px !important;
  z-index: 1056 !important;

  &.ng-select-bottom {
    box-shadow: 2px 2px 2px 1px rgb(9 9 9 / 43%);
    transform: translateY(-8px);
  }
}

.ng-select .ng-select-container {
  color: $white;
  border: 1px solid $secondary;
  background-color: $secondary;
  justify-content: space-between;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: $secondary;
  border-color: $white;
}

.ng-select.ng-select-opened.readonly-dropdown > .ng-select-container {
  background: $secondary;
  border-color: transparent;
  cursor: default !important;
}

//fixes dropdown text color of single select
.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 0.75rem;
  color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: $secondary;
  font-weight: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: $white;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: $secondary;
  color: $white;
  font-size: 0.75rem;
  white-space: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: $white;
  background-color: $secondary;
}

.ng-select.withCheckbox, .ng-dropdown-panel.withCheckbox {
  .ng-dropdown-panel-items
  .ng-option-selected:not(.ng-optgroup) {
    &:after {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      left: 8px;
      top: 8px;
      background: 50%/50% 50% no-repeat;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-color: $primary !important;
      position: absolute;
      left: 8px;
      box-shadow: 0 0 0 1px $primary !important;
      border-radius: 4px;
    }
  }

  .ng-dropdown-panel-items
  .ng-option:not(.ng-option-disabled) {
    padding-left: 30px;
    position: relative;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-color: $secondary;
      position: absolute;
      left: 8px;
      box-shadow: 0 0 0 1px #4f5271;
      border-radius: 4px;
    }

  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #34324e;
  color: $white;
}

.ng-value-container {
  max-width: 80%;
  font-size: 0.75rem;
}

.ng-placeholder {
  font-size: 0.75rem !important;
  padding: 4px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: $primary;
  max-width: 100%;
}

//fixes text color on pink bacground multiselect
.ng-select.ng-select-multiple span.ng-value-label {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  font-size: 0.75rem;
  color: white;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: transparent;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: #b11b69;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $white;
  box-shadow: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened).readonly-dropdown
  > .ng-select-container {
  border-color: transparent;
  box-shadow: none;
  cursor: default !important;
}

.ng-select.ng-select-multiple .ng-select-container {
  position: relative;
}

.ng-select.dark .ng-select-container {
  background-color: #1c1a32;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #4f5271;
  font-size: 0.75rem;
}

span.ng-value-icon.left {
  display: none !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #2b2940;
}

.ng-select.readonly-dropdown:not(ng-select-disabled) > .ng-select-container {
  background-color: $secondary;
  opacity: 0.5 !important;
}

.ng-select.ng-select-disabled.readonly-dropdown > .ng-select-container {
  background-color: $secondary;
  opacity: 0.5 !important;
}

.ng-select .ng-arrow-wrapper {
  width: 30px;
}

.ng-select .ng-arrow-wrapper .ng-select span.ng-arrow-wrapper {
  position: absolute;
  right: 0;
  width: auto;
  padding-right: auto;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: $white transparent transparent;
}

span.ng-arrow {
  border: none !important;
  background-image: url(/assets/dropdown-chevron.svg) !important;
  background-repeat: no-repeat;
  height: 0.375rem !important;
  background-position-y: center;
  width: 30px !important;
  top: -2px !important;
  left: 0;
  background-size: contain;
  background-position-x: center;
}

.ng-select-opened:not(.readonly-dropdown) span.ng-arrow {
  transform: rotateZ(180deg);
  left: 1px;
}

.ng-select.readonly-dropdown .ng-arrow-wrapper {
  cursor: default !important;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.ng-select.dark .ng-select-container {
  background-color: #1c1a32;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  margin-top: 3px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

.form-control:disabled,
.form-control[readonly] {
  border: 0;
}

/* Scrollbar styles */
/* Works on Firefox */
* {
  scrollbar-width: 10px;
  scrollbar-color: var(--trackThumb) $dark !important;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: $dark !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--trackThumb) !important;
  border-radius: 20px;
}

/* autofill styles */

input:-webkit-autofill {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-moz-autofill {
  -moz-text-fill-color: $white;
  -moz-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-o-autofill {
  -o-text-fill-color: $white;
  -o-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:-khtml-autofill {
  -khtml-text-fill-color: $white;
  -khtml-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}

input:focus:-webkit-autofill {
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-moz-autofill {
  -moz-text-fill-color: $white;
  -moz-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-o-autofill {
  -o-text-fill-color: $white;
  -o-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}
input:focus:-khtml-autofill {
  -khtml-text-fill-color: $white;
  -khtml-box-shadow: 0 0 0px 1000px var(--autoFill) inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--autoFill) inset !important;
}

::-webkit-scrollbar-corner {
  background-color: var(--dark);
}

/* Datatable styles */

.ngx-datatable.bootstrap {
  font-size: 16px;
  .datatable-header {
    color: #d1e6f366 !important;
    font-size: 0.625rem;
    opacity: 1;
    .datatable-header-cell {
      text-transform: uppercase;
      &:first-child {
        padding-left: 0.75rem;
      }
    }
  }
  .datatable-body {
    overflow-y: auto;
    .empty-row {
      position: relative;
      padding: 0.75rem;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
    .datatable-body-row {
      vertical-align: middle !important;
      border-top: 1px solid $secondary !important;
      &:hover {
        background-color: rgb(43 41 64 / 40%);
        transition: background-color 300ms linear;
      }
      .datatable-body-cell {
        font-size: 0.75rem;
        &:first-child {
          padding-left: 0.75rem;
        }
      }
      .datatable-body-cell.summary-value {
        overflow: visible;
      }
    }
  }
  .datatable-footer {
    background-color: $dark;
    font-size: 0.75rem;
    opacity: 0.5;
    color: $white;
    .datatable-footer-inner {
      overflow: hidden;
      height: auto !important; // Ensures that the pagination controls are visible
    }
    // pager styling
    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;
      ul {
        li {
          margin: 10px 0px;
          &:not(.disabled) {
            &.active,
            &:hover {
              a {
                font-weight: bold;
              }
            }
          }
        }
      }
      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
      }
      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
}

.ngx-datatable.bootstrap.contained .datatable-body {
  height: calc(100vh - 280px) !important;
}

.fullHeight .datatable-body {
  height: auto !important;
}
.ngx-datatable.requestList
  .datatable-header
  .datatable-header-cell.sortable.sort-active
  .datatable-header-cell-label {
  color: $white !important;
  opacity: 1;
}

.ngx-datatable.requestList .datatable-icon-sort-unset::before {
  display: none; //hide up/down sort
}

.ngx-datatable.requestList .datatable-icon-up::before,
.ngx-datatable.requestList .datatable-icon-down::before {
  display: block;
  position: relative;
  content: "";
}

.ngx-datatable .datatable-header .datatable-header-cell.sort-active .sort-btn {
  cursor: pointer;
  display: inline-block;
  width: 14px;
  line-height: 100%;
  vertical-align: middle;
  background-image: url(/assets/sort-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 4px;
  top: -1px;
  position: relative;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell.sort-active
  .sort-btn.sort-asc {
  transform: rotateZ(180deg);
}
.ngx-datatable
  .datatable-header
  .datatable-header-cell.sort-active
  .sort-btn.sort-desc {
  transform: rotateZ(0deg);
}

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li {
  margin: 0px;
}

.ngx-datatable.requestList
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: $secondary;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background-color: transparent;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #2b2940 !important;
  color: #fff;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row:hover {
  background-color: rgba(43, 41, 64, 0.4);
}

.ngx-datatable.requestList
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 0.75rem;
  padding-left: 0;
}

.ngx-datatable.requestList .datatable-body .datatable-body-row .salesDeskCell,
.ngx-datatable.requestList
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell:first-child {
  padding-left: 0.75rem !important;
}

.ngx-datatable.requestList
  .datatable-body
  .datatable-body-row
  .datatable-body-cell.statusCell {
  padding-left: 0;
}

.ngx-datatable.requestList
  .datatable-body
  .datatable-body-row
  .datatable-body-cell.actionCell
  a {
  color: $primary;
  text-align: center;
}

.ngx-datatable.requestList
  .datatable-body
  .datatable-body-row
  .datatable-body-cell
  span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.ngx-datatable.requestList
  .datatable-body
  .datatable-body-row
  .distContactCell
  span {
  display: block;
  width: 100%;
}

.ngx-datatable.requestList .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.2rem 0;
  border-bottom: 0px solid $secondary;
}

.ngx-datatable.requestList .datatable-footer .datatable-pager ul li {
  margin: 0px;
}

.ngx-datatable.requestList
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: $secondary;
}

.ngx-datatable.requestList .datatable-header .datatable-header-cell.reviewCell {
  display: none !important;
}

.ngx-datatable.requestList .datatable-footer .page-count {
  padding: 0;
}

.ngx-datatable.list-table .datatable-body {
  overflow-x: hidden;
  display: inline;
}

#release-list .ngx-datatable {
  height: inherit;
}

#release-list datatable-scroller {
  width: unset !important; /* Fix the unnecessarily long horizontal scroll */
}

#release-list .ngx-datatable {
  height: inherit;
}

#release-list .ngx-datatable.requestList .datatable-footer {
  visibility: collapse;
}

.ngx-datatable .release-status-dev {
  background-color: #14152b;
}
.ngx-datatable .release-status-test {
  background-color: #14152b;
}
.ngx-datatable .release-status-staging {
  background-color: #0e0f21;
}
.ngx-datatable .release-status-uat {
  background-color: #0a0b15;
}
.ngx-datatable .release-status-prod {
  background-color: #010102;
}

.section-tab-layout {
  border-bottom: $dark 2px solid;
  padding-bottom: 12px;
  .section-tabs {
    display: flex;
    .section-tab {
      border-radius: 16px 16px 0px 0px;
      margin-right: 2px;
      padding: 1em 3em;
      font-size: 14px;
      margin-bottom: -12px;
      border: $dark 3px solid;
      background-color: $dark;
    }
    .section-tab.active {
      background-color: $dark;
      color: white;
      font-weight: bold;
    }
  }
}

.sectionButtonContainer {
  display: flex;
}

.sectionButton {
  margin: 0 10px 10px 0;
  padding: 8px 20px;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #1c1a32;
  font-size: 14px;
  text-transform: uppercase;

  &.invalid {
    border-bottom: 1px solid #d1b91f;
  }
}

.sectionButton:hover {
  background-color: rgb(28, 26, 50, 0.5);
}

.activeSectionButton {
  border: 1px solid #d11f7a;
}

.transparent-btn-primary {
  border-radius: 20px;
  color: $white;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  font-size: 75%;
  border: 1px solid rgb(209, 31, 122);
  border-radius: 1.25rem;
  opacity: 1;
}

// ng-select dropdown read-only fix
.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}

.ng-select.disable-dropdown ng-dropdown-panel {
  display: none;
}

.ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  background-color: $primary !important;
}

.ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value {
  border-color: $primary !important;
}

.toast-modal .toast-body {
  padding: 0.67rem 0.75rem 0.75rem 0.75rem;
}

.afterSignOffModal .modal-content {
  border: none !important;
  height: 500px !important;
}

// todo IN VARS
.modal-header {
  padding: 0.75rem 2rem;
}
.modal-header button.close {
  margin: 0;
  padding: 0.3125rem;
  font-size: 0.875rem;
  color: var(--primary);
}

// BOOTSTRAP STYLE ADDITIONS

.btn-primary {
  border: none;

  &:hover {
    background-color: lighten($primary, 10%);
    fill: lighten($primary, 10%);
  }
}

.icon-btn-primary {
  border: none;

  &:hover {
    filter: brightness(150%);
  }

  &:active {
    filter: brightness(75%);
  }
}

.btn-secondary {
  border: none;

  &:hover {
    background-color: lighten($secondary, 10%);
  }
}

.btn-success {
  border: none;

  &:hover {
    background-color: lighten($success, 10%);
  }
}

.btn-danger {
  border: none;

  &:hover {
    background-color: lighten($danger, 10%);
  }
}

.btn-warning {
  border: none;

  &:hover {
    background-color: lighten($warning, 10%);
  }
}

.btn-info {
  border: none;

  &:hover {
    background-color: lighten($info, 10%);
  }
}

.btn-dark {
  border: none;

  &:hover {
    background-color: lighten($dark, 10%);
  }
}

.btn-darkerGrey {
  border: none;
  background-color: #3e4055;
  &:hover {
    background-color: lighten(#3e4055, 10%);
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(62 64 85 / 50%);
    background-color: brightness(75%);
  }
}

.btn-outline-primary {
  color: $white;

  &:hover {
    background-color: rgba($primary, 0.1);
  }
}

.btn-outline-secondary {
  color: $white;

  &:hover {
    background-color: rgba($secondary, 0.3);
  }
}

.btn-outline-success {
  color: $white;

  &:hover {
    background-color: rgba($success, 0.1);
  }
}

.btn-outline-danger {
  color: $white;

  &:hover {
    background-color: rgba($danger, 0.1);
  }
}

.btn-outline-warning {
  color: $white;

  &:hover {
    color: $white;
    background-color: rgba($warning, 0.1);
  }
}

.btn-outline-info {
  color: $white;

  &:hover {
    background-color: rgba($info, 0.3);
  }
}

.btn-outline-light {
  color: $white;

  &:hover {
    color: $white;
    background-color: rgba($light, 0.1);
  }
}

.form-control:disabled {
  opacity: 0.6;
}

.form-control:focus {
  box-shadow: none !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary;
  border-radius: 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $primary;
  border-radius: 4px;
}

.custom-switch .custom-control-label::before {
  background-color: $info;
  border-color: $info;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: $info;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary;
}

.custom-switch .custom-control-label::after {
  background-color: $white;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: $white;
}

.close {
  opacity: 1;
}

.card.bg-light .card-body {
  color: $secondary;
}

.card-header {
  background-color: transparent;
}

.list-group-item-action:not(.active) {
  opacity: 0.32;
}

custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.32;
}

/* CSS hacks for modals */
.modal-header {
  text-transform: uppercase;
}
.modal-footer {
  padding: 0.75rem 2rem;
}

.modal-body {
  padding: 1rem 2rem;
}

.modal-body p {
  font-size: 0.875rem;
}

.modal-body li {
  font-size: 14px;
  list-style: disc;
  margin-left: 1rem;
}

.modal {
  &.show .modal-dialog {
    transition: 0.25s all;
  }

  &.custom-show .modal-dialog {
    opacity: 1;
    transform: translate(0, 0);
  }
  .modal-body {
    &:focus {
      outline: none;
    }
  }
}

.viewDDQDiff {
  display: block;
  margin: 10px 0;
}

.DDQSubheadHr {
  border-color: red;
  margin-top: -20px;
}

ngb-typeahead-window .dropdown-item {
  padding: 8px 15px;
}
.progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 0;
  padding: 0;
  margin: 0;
  top: 0;
  content: "Loading";
  .container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50px;
    content: "Loading";

    .bar {
      transition: all 0.2s linear;
      -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);

      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      content: "Loading";
      position: absolute;
      width: 100%;
      height: 25px;
    }
  }
}
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/* SEARCH BAR */
.form-control.input.searchbar {
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  background-image: url(/assets/search-icon-pink.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.form-control.input.searchbar::placeholder {
  color: #d1e6f3;
  opacity: 0.32;
}

.dark.form-control.input {
  background-color: $dark;
}

/* INPUT NUM-PERCENT */
.form-control.input.percentNum {
  border-radius: 0.25rem 0 0 0.25rem;
}

.form-control.input.percentNum.ng-untouched.ng-pristine.ng-invalid,
.form-control.input.percentNum.ng-pristine.ng-invalid.ng-touched,
.form-control.input.percentNum.ng-dirty.ng-touched.ng-invalid {
  border-right: none;
}

/* INPUT CLEAR 'X' */
.ng-select span.ng-clear {
  color: #d1e6f3;
  opacity: 0.32;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #d1e6f3;
  opacity: 0.5;
}

// AUTHO O3 STYLES
/* login start */

.loginWrap {
  display: flex;
  background-color: var(--dark);
  height: 100vh;
  min-height: 667px;
  overflow: auto;
}
.logoSpacer {
  width: 50%;
  display: flex;
  justify-content: center;
}
.loginLogoContainer {
  width: 480px;
  // height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.loginLogo {
  width: 240px;
  min-width: 240px;
  height: 67px;
  position: relative;
  margin: 0 auto 0;
}

.footer {
  text-align: center;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

amplify-authenticator {
  position: relative;
  z-index: 999;
  min-height: 370px;
}

[data-amplify-authenticator] {
  --amplify-components-textfield-color: #fff;
  --amplify-colors-background-primary: var(--input-bg);
  --amplify-components-button-primary-background-color: var(--primary);
  --amplify-components-button-primary-hover-background-color: #b51466;
  --amplify-components-button-border-radius: 30px;
  --amplify-components-fieldcontrol-border-radius: 30px;
  --amplify-components-button-line-height: var(--amplify-line-heights-large);
  --amplify-components-fieldcontrol-color: var(--white);
  --amplify-components-fieldcontrol-line-height: var(
    --amplify-line-heights-large
  );
  --amplify-components-fieldcontrol-border-style: none;
  --amplify-components-icon-height: 1.5em;
  --amplify-components-button-primary-active-background-color: #b51466;
  --amplify-components-button-primary-focus-background-color: #b51466;
  //amplify-button--disabled
  --amplify-components-button-primary-disabled-background-color: #b51466;
  --amplify-components-button-primary-disabled-border-color: #b51466;
  --amplify-components-button-primary-disabled-color: #fff;
  --amplify-internal-button-loading-background-color: #b51466;
  --amplify-internal-button-loading-border-color: #b51466;
  --amplify-components-button-loading-color: #fff;
  --amplify-components-authenticator-container-width-max: 25rem;
  --amplify-components-text-error-color: #fff;
}

.amplify-button--loading {
  background-color: #b51466 !important;
  border-color: #b51466 !important;
  color: #fff !important;
}

.amplify-button[data-variation="link"],
.amplify-button[data-variation="link"]:active,
.amplify-button[data-variation="link"]:focus {
  width: auto;
  color: #565a70;
  background: none;
  outline: none;
  box-shadow: none;
}
.amplify-button[data-variation="link"]:hover {
  width: auto;
  color: var(--primary);
  background: none;
}
// .amplify-flex.amplify-field-group .amplify-input.amplify-field-group__control {
//   border-bottom-right-radius: 0px !important;
//   border-top-right-radius: 0px !important;
// }

button.amplify-field-group__control.amplify-input.amplify-button.amplify-button--default.amplify-button--medium {
  background-color: #34364d;
  border-color: #1c1a32;
  border-left: 0;
}

button.amplify-field-group__control.amplify-input.amplify-button.amplify-button--default.amplify-button--medium:hover {
  background-color: #2b2940;
}

.amplify-label {
  display: none;
}
.amplify-field {
  // background: #34364d;
  border-radius: 30px;
}
.amplify-field-group__control.amplify-field__show-password.amplify-button {
  border: none;
}
.amplify-field-group__control.amplify-field__show-password.amplify-button:hover,
.amplify-field-group__control.amplify-field__show-password.amplify-button:focus {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}
.amplify-field-group__control.amplify-field__show-password.amplify-button svg {
  color: #fff;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none;
}

[data-amplify-authenticator] [data-amplify-container] p {
  text-align: center;
}
[data-amplify-authenticator] [data-amplify-container] img {
  margin: 0 auto;
}
[data-amplify-copy-svg] svg {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg)
    brightness(106%) contrast(94%);
}

div[data-amplify-sign-up-errors] {
  padding-left: 20px;
}

.bgImg {
  width: 50%;
  background-image: url("/assets/Delta-Capita-MOI.jpg");
  background-repeat: no-repeat;
  z-index: 0;
  /* Chrome, still requires prefix in 2022 */
  -webkit-mask: url("/assets/l-path.svg");
  /* Firefox and Safari */
  mask: url("/assets/l-path.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: right center;
  -webkit-mask-position: right center;
  background-size: cover;
  background-position: right center;
}

h3.amplify-heading {
  color: #fff;
  text-align: center;
  font-family: lato, Roboto, Helvetica Neue, sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.amplify-alert[data-variation="error"] {
  background-color: transparent;
  color: #fff;
}

// overrides

body .amplify-input:focus {
  border: 1px solid #fff !important;
}

body .amplify-input {
  background-color: #2b2940;
}

body .amplify-input,
body .amplify-button {
  border: 1px solid #1c1a32;
}

.amplify-icon {
  fill: #fff;
}

body .amplify-input::placeholder {
  color: #606579;
}

body .amplify-button.amplify-button--primary:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(216, 65, 142, 0.5);
}

button.amplify-field-group__control.amplify-field__show-password {
  border-top: 1px solid #1c1a32 !important;
  border-bottom: 1px solid #1c1a32 !important;
  border-right: 1px solid #1c1a32 !important;
}

button.amplify-field-group__control.amplify-field__show-password:focus-visible {
  border: 1px solid #fff !important;
}

.amplify-button.amplify-button--fullwidth.amplify-button--link.amplify-button--small:focus-visible {
  border: 1px solid #fff !important;
}

button.amplify-button.amplify-button--fullwidth.amplify-button--medium.amplify-button--primary
  :not(:disabled):not(.disabled):active,
button.amplify-button.amplify-button--fullwidth.amplify-button--medium.amplify-button--primary
  :not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #a51860 !important;
  border-color: #99175a !important;
}

button.amplify-button.amplify-button--fullwidth.amplify-button--medium.amplify-button--primary
  :not(:disabled):not(.disabled):active:focus,
button.amplify-button.amplify-button--fullwidth.amplify-button--medium.amplify-button--primary
  :not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 65, 142, 0.5) !important;
}

button.amplify-button.amplify-button--fullwidth.amplify-button--medium.amplify-button--primary:hover {
  background-color: #e34093;
  fill: #e34093;
}

//  media queries

@media (max-width: 820px) {
  .loginLogoContainer {
    width: 100%;
  }
  .logoSpacer {
    width: 100%;
  }
  .bgImg {
    width: 0;
  }
}
/* login end */

// END OF AUTHO O3 STYLES

//modal position fix for xxl modals
.xlScrollableModal.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-xxl .modal-xl {
  max-width: 90%;
}

.lifecycle-modal {
  .modal-lg {
    max-width: 800px;
  }
}

// GETS RID OF ARROWS IN INPUTS WHERE TYPE=NUMBER
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// FIXES Y ALIGNMENT FOR FORM ROWS (for long labels but small inputs)
.form-row {
  align-items: end;
}

.form-row.centered {
  align-items: center; // add centered class to form-row to center
}

// XS button type
.btn-group-xs > .btn,
.btn-xs {
  padding: 0.25rem 1rem;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.2;
  border-radius: 0.625rem;
}

.observations-modal .modal-xl {
  max-width: 80vw !important;
  max-height: 40vh !important;
}

.observations-modal .modal-xl .container {
  max-width: inherit;
}

.icon-img {
  width: 18px;
}

.editor-container {
  height: 100% !important;
}

/* Table detail  */

.table.table-detail {
  margin-bottom: 0;
  font-size: 12px;
  vertical-align: middle;
}

.datatable-row-detail .card {
  background-color: #2b2940;
  margin: 0px 24px 23px;
  padding: 0 20px;
}

.datatable-row-detail .card .table thead th,
.table thead th {
  /* border-bottom: 1px solid #5a6075; */
  border-top: none;
  color: #5a6075;
  padding: 11px 0 6px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
}

.table.secondary thead th,
.table.secondary tbody td {
  border-color: var(--white60);
}

datatable-body-cell.datatable-body-cell.expandCell {
  padding-left: 0 !important;
}

.datatable-icon-down::before,
.datatable-icon-right::before {
  font-size: 20px;
}

.table.table-detail tr {
  position: relative;
}

.table-sm.table-detail td {
  padding: 0.75rem 0.75rem 0.75rem 0;
  height: 40px;
}

.table-sm tbody td {
  vertical-align: middle;
  font-size: 12px;
  padding-left: 0;
  height: 40px;
}

.table.table-detail
  tr:not(.trRuleDetail, .expandedTr, table.table.table-detail.table-sm
    tbody
    > tr:last-child):after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.06);
  display: block;
}

table.ruleDetail th {
  border: none;
}

/* BOOTSTRAP 5 Hacks */

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row {
  align-items: end;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-row.centered {
  align-items: center;
}

.form-group {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

@media (min-width: 992px) {
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.btn-link:focus,
.btn-link:active,
.btn-link:active:focus {
  box-shadow: none;
}

.btn-link {
  text-decoration: none;
}

/* BS4 checkbox */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.checkboxContainer.custom-checkbox.custom-control {
  position: relative;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.0625rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

label {
  font-size: 0.688rem;
  margin-bottom: 0.375rem;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.0625rem;
  left: -1.75rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #d11f7a;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #d11f7a;
  box-shadow: none;
}

.custom-control-label:before {
  pointer-events: none;
  background-color: #1c1a32;
  border: 1px solid #4f5271;
}

.badge-danger {
  background-color: $danger;
}

.badge-success {
  background-color: $success;
}

.badge-warning {
  background-color: $yellow;
}

.table > :not(caption) > * > * {
  color: #fff;
  background-color: transparent;
  padding: 0;
}

.table a,
.datatable-body-cell-label a,
a.datatable-icon,
a.datatable-icon-right {
  text-decoration: none;
}

.percentNumberContainer.input-group-append {
  display: flex;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

#deskIdSelector ng-dropdown-panel .ng-option.ng-option-disabled {
  white-space: normal;
  margin-left: -15px;
}

.skelo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #2b2940 0%, #3a3856 50%, #2b2940 100%);
  background-size: 200% 100%;
  animation: skelo 2s infinite;

  @keyframes skelo {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}
